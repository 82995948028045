import './styles.scss';

import { Button, InputCheckbox, InputTextbox, ModalMd, ModalSm, SessionStorage } from 'c2-common-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../utils/intlFormatterHOC';

const UploadMetadataModal = ({ isOpen, isUpdate, onFormatIntl, onSetFederatedAuthOnly, onSubmit, onToggleModal }) => {
  const [isAuthOnly, setIsAuthOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metadataFile, setMetadataFile] = useState(null);
  const [metadataUrl, setMetadataUrl] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleCheckboxClick = () => setIsAuthOnly(!isAuthOnly);

  const handleConfirm = () => {
    const metadata = metadataFile || metadataUrl.trim();

    if (isAuthOnly) {
      SessionStorage.setItem('fed_auth_only', true);
      onSetFederatedAuthOnly(true);
    }

    onSubmit(metadata);
    handleToggleConfirmModal();
    handleToggle();
  };

  const handleInputChange = event => {
    document.getElementById('fileUploadInput').value = '';
    setMetadataFile(null);
    setMetadataUrl(event.currentTarget.value);
  };

  const handleFileUploadChange = () => {
    setMetadataFile(document.getElementById('fileUploadInput').files[0]);
    setMetadataUrl('');
  };

  const handleSubmit = () => {
    if (isUpdate) {
      handleToggleConfirmModal();
    } else {
      onSubmit(metadataFile || metadataUrl);
      handleToggle();
    }
  };

  const handleToggle = () => onToggleModal();

  const handleToggleConfirmModal = () => setModalOpen(!modalOpen);

  const handleTriggerFileUpload = () => document.getElementById('fileUploadInput').click();

  return (
    <ModalMd
      classes="user-modal-md upload-metadata-modal"
      onHandleClose={handleToggle}
      open={isOpen}
      title={onFormatIntl('userModal.uploadMetadataTitle')}
    >
      {isUpdate && (
        <div className="modal-subheader">
          <span className="warning-icon" />
          <span className="warning-text">Updating SAML Metadata may cause issues with your SAML provider.</span>
        </div>
      )}
      <div className="modal-body">
        <div className="caption">
          Ensure your URL, Certificate, and Identifiers match exactly what was provided by your access management
          provider.
        </div>
        <div className="section">
          <InputTextbox
            classes="metadata-textbox"
            label="Metadata URL"
            name="metadataUrlInput"
            onChange={handleInputChange}
            placeholder="Enter URL"
            value={metadataUrl}
          />
          <span className="or">or</span>
          <Button classes="file-upload" onClick={handleTriggerFileUpload}>
            Upload File
          </Button>
          <span className="file-name">{metadataFile && metadataFile.name}</span>
          <input
            id="fileUploadInput"
            name="fileUploadInput"
            onChange={handleFileUploadChange}
            style={{ display: 'none' }}
            type="file"
          />
        </div>
        <div className="section">
          <InputCheckbox name="Enable for authentication only" checked={isAuthOnly} onChange={handleCheckboxClick} />
        </div>
      </div>
      <div className="modal-footer">
        <Button color="secondary" disabled={loading} onClick={handleToggle}>
          <FormattedMessage id="userModal.cancelButton" />
        </Button>
        <Button color="primary" disabled={!metadataFile && !metadataUrl} onClick={handleSubmit}>
          <FormattedMessage id="userModal.saveButton" />
        </Button>
      </div>
      {modalOpen && (
        <ConfirmModal
          loading={loading}
          onConfirm={handleConfirm}
          onToggleConfirmModal={handleToggleConfirmModal}
          onFormatIntl={onFormatIntl}
        />
      )}
    </ModalMd>
  );
};

const ConfirmModal = ({ loading, onConfirm, onToggleConfirmModal, onFormatIntl }) => (
  <ModalSm
    classes="user-modal-sm metadata-confirm"
    onHandleClose={onToggleConfirmModal}
    open
    title={onFormatIntl('userModal.confirmTitle')}
  >
    <div className="modal-subheader">
      <span className="warning-icon" />
      <span className="warning-text">You are about to change your SAML Metadata!</span>
    </div>
    <div className="modal-body">
      <span className="message">
        <FormattedMessage id="userModal.metadataUpdateConfirm" />
      </span>
    </div>
    <div className="modal-footer">
      <Button color="secondary" disabled={loading} onClick={onToggleConfirmModal}>
        <FormattedMessage id="userModal.cancelButton" />
      </Button>
      <Button color="primary" disabled={loading} onClick={onConfirm}>
        <FormattedMessage id="userModal.proceedButton" />
      </Button>
    </div>
  </ModalSm>
);

UploadMetadataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  onFormatIntl: PropTypes.func,
  onSetFederatedAuthOnly: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};

ConfirmModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onFormatIntl: PropTypes.func.isRequired,
  onToggleConfirmModal: PropTypes.func.isRequired,
};

export default IntlFormatterHOC(UploadMetadataModal);
