import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import {
  DEFAULT_ERROR_MESSAGE,
  ERROR,
  KEY_STATUS_ACTIVE,
  KEY_STATUS_EXPIRING,
  KEY_STATUS_REVOKED,
} from '../../constants/appVariables';
import { getTenantInfoQuery } from '../../graphql/queries/getTenantInfo';
import { postGenerateApiKey, postRegenerateApiKey, postRenewApiKey, postRevokeApiKey } from '../../webAPIs/apiKeys';
import { lmGraphQL } from '../../webAPIs/licenseManager';
import { addNotification } from '../notifications/notifications.slice';
import { setActiveApiKeys, setExpiredApiKeys, setPackageNames } from './apiKeys.slice';

export const fetchApiKeys = createAsyncThunk('userAdmin/fetchApiKeys', async ({ signal = null }, thunkAPI) => {
  try {
    const res = await lmGraphQL({
      query: getTenantInfoQuery(thunkAPI.getState().sessionUser.userpoolId),
      signal,
    });

    const resGetTenantInfo = res.data.getTenantInfo;
    const apiKeys = resGetTenantInfo.APIKeys || [];
    const ccProductDetails = resGetTenantInfo.Products?.filter(({ SKU }) => SKU === 'CC') || [];

    if (ccProductDetails.length) {
      const packageNames = ccProductDetails[0].Packages.map(({ Name }) => Name);

      thunkAPI.dispatch(setPackageNames(packageNames));
    }

    if (apiKeys.length) {
      const sortedApiKeys = apiKeys.sort((a, b) => {
        const aDateValue = a.Revoked || a.Expiration;
        const bDateValue = b.Revoked || b.Expiration;

        return aDateValue < bDateValue ? 1 : -1;
      });

      const expiredKeysArr = sortedApiKeys
        .filter(({ Status }) => Status !== KEY_STATUS_ACTIVE)
        .map(apiKey => {
          if (apiKey.Revoked && apiKey.Status === KEY_STATUS_REVOKED) {
            apiKey.Expiration = apiKey.Revoked;
          }

          apiKey.Expiration = moment(apiKey.Expiration).format('MM/DD/YYYY');

          return apiKey;
        });

      const activeKeysArr = sortedApiKeys
        .filter(({ Status }) => Status === KEY_STATUS_ACTIVE)
        .map(apiKey => {
          if (moment(apiKey.Expiration).isBefore(moment().add(7, 'days'))) {
            const timeDelta = moment(apiKey.Expiration).diff(moment(), 'days', true);

            apiKey.Status = KEY_STATUS_EXPIRING;
            apiKey.DaysTillExpire =
              timeDelta < 1 ? moment(apiKey.Expiration).diff(moment(), 'hours') / 24 : Math.ceil(timeDelta);
          }

          apiKey.Expiration = moment(apiKey.Expiration).format('MM/DD/YYYY');

          return apiKey;
        });

      if (!activeKeysArr.length) {
        activeKeysArr.push(expiredKeysArr.shift());
      }

      thunkAPI.dispatch(setActiveApiKeys(activeKeysArr));
      thunkAPI.dispatch(setExpiredApiKeys(expiredKeysArr));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const generateApiKey = createAsyncThunk('userAdmin/generateApiKey', async (_, thunkAPI) => {
  try {
    const res = await postGenerateApiKey();

    if (res.status === 'success') {
      await thunkAPI.dispatch(fetchApiKeys({}));
    } else {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const regenerateApiKey = createAsyncThunk('userAdmin/regenerateApiKey', async ({ key }, thunkAPI) => {
  try {
    const res = await postRegenerateApiKey(key);

    if (res.status === 'success') {
      await thunkAPI.dispatch(fetchApiKeys({}));
    } else {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const renewApiKey = createAsyncThunk('userAdmin/renewApiKey', async ({ key }, thunkAPI) => {
  try {
    const res = await postRenewApiKey(key);

    if (res.status === 'success') {
      await thunkAPI.dispatch(fetchApiKeys({}));
    } else {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});

export const revokeApiKey = createAsyncThunk('userAdmin/revokeApiKey', async ({ key }, thunkAPI) => {
  try {
    const res = await postRevokeApiKey({ key });

    if (res.status === 'success') {
      await thunkAPI.dispatch(fetchApiKeys({}));
    } else {
      thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
    }
  } catch (err) {
    Sentry.captureException(err);

    thunkAPI.dispatch(addNotification({ type: ERROR, message: DEFAULT_ERROR_MESSAGE }));
  }
});
