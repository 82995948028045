import './styles.scss';

import {
  Button,
  InputSearchTypeaheadGroups,
  InputTextbox,
  LoadingSpinnerMd,
  ModalMd,
  TooltipNoHandler,
  Utils,
} from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';

const CreateUserGroupModal = ({
  allowedRoles,
  isOpen,
  onFormatIntl,
  onSubmit,
  onToggleModal,
  selectedUsers,
  userGroups,
  users,
}) => {
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [groupName, setGroupName] = useState('');
  const [selectedUsersState, setSelectedUsersState] = useState([]);
  const [selectedUsersForListDisplay, setSelectedUsersForListDisplay] = useState([]);
  const [loading, setLoading] = useState(true);

  const createUserGroupItems = useCallback(() => {
    const userGroupsArr = userGroups.map(obj => ({
      id: obj.id,
      displayName: obj.name,
      subGroup: [],
    }));

    userGroupsArr.push({
      id: 'default',
      displayName: 'Default',
      subGroup: [],
    });

    userGroupsArr.forEach(userGroup => {
      const usersBelongingToUserGroup = users
        .filter(({ groups }) =>
          userGroup.displayName === 'Default' ? groups.length === 0 : groups.indexOf(userGroup.displayName) > -1
        )
        .map(({ id, name, email }) => ({
          id,
          displayName: name,
          displayAdditional: email,
        }));

      userGroup.subGroup = usersBelongingToUserGroup;
    });

    return userGroupsArr;
  }, [userGroups, users]);

  const userGroupItems = useMemo(() => createUserGroupItems(), [createUserGroupItems]);

  const handleInputChange = event => {
    const target = event.currentTarget;

    setCreateButtonDisabled(target.value === '');
    setGroupName(target.value);
  };

  const handleRemoveUser = event => {
    const userId = event.target.id;
    const filteredSelectedUsers = selectedUsersState.filter(selectedUserObj => selectedUserObj.id !== userId);

    handleSelectUser(filteredSelectedUsers);
  };

  const handleSelectUser = useCallback(
    items => {
      const itemsCopy = Utils.deepClone(items);

      itemsCopy.forEach(item => {
        const user = users.find(user => user.id === item.id);
        const { groups, roles } = user || { groups: [], roles: [] };

        item.roles = roles
          .map(role => {
            const roleDetails = Object.values(allowedRoles).filter(({ id }) => id === role)[0];

            return roleDetails !== undefined ? roleDetails.name : role;
          })
          .sort()
          .join(', ');

        item.groups = [...groups].sort().join(', ');
      });

      setSelectedUsersState(items);
      setSelectedUsersForListDisplay(
        itemsCopy.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
      );
    },
    [allowedRoles, users]
  );

  const handleSubmit = () => {
    const usersList = selectedUsersState.map(selectedUserObj => ({
      id: selectedUserObj.id,
      email: selectedUserObj.displayAdditional,
      name: selectedUserObj.displayName,
    }));

    onSubmit(groupName, usersList);
    handleToggle();
  };

  const handleToggle = () => {
    onToggleModal();
  };

  useEffect(() => {
    const selectedUsersArr = Object.keys(selectedUsers).map(username => ({
      id: username,
      displayName: selectedUsers[username].name,
      displayAdditional: selectedUsers[username].email,
    }));

    handleSelectUser(selectedUsersArr);
    setLoading(false);
  }, [handleSelectUser, selectedUsers]);

  return (
    <ModalMd
      classes={classnames('create-user-group-modal')}
      onHandleClose={handleToggle}
      open={isOpen}
      title={onFormatIntl('userModal.createUserGroupTitle')}
    >
      <div className="modal-body">
        {loading && <LoadingSpinnerMd />}
        {!loading && (
          <>
            <InputTextbox
              classes="group-name-textbox"
              label={onFormatIntl('userModal.userGroupNameLabel')}
              name="groupNameInput"
              onChange={handleInputChange}
              placeholder={onFormatIntl('userModal.userGroupNamePlaceholder')}
              value={groupName}
            />
            <div className="typeahead-wrapper">
              <InputSearchTypeaheadGroups
                classes="select-users-typeahead"
                label={onFormatIntl('userModal.selectUsersLabel')}
                items={userGroupItems}
                onSelect={handleSelectUser}
                placeholder={onFormatIntl('userModal.selectUsersPlaceholder')}
                selectedItems={selectedUsersState}
              />
              {selectedUsersState.length > 0 && (
                <span className="selected-user-caption">{`${selectedUsersState.length} user(s) selected`}</span>
              )}
              <span className="info-icon" />
              <TooltipNoHandler
                infoArr={['You can only select multiple users who have similar roles']}
                showOnClick={false}
              />
            </div>
            <div className="selected-users-list">
              {selectedUsersForListDisplay.map((selectedUserObj, i) => (
                <div className="list-item" key={i}>
                  <div className="top-row">
                    <span className="user-name">{selectedUserObj.displayName}</span>
                    <span className="user-email">{selectedUserObj.displayAdditional}</span>
                    <span className="remove-button" id={selectedUserObj.id} onClick={handleRemoveUser} />
                  </div>
                  <div className="bottom-row">
                    <span className="user-roles">{selectedUserObj.roles}</span>
                    {selectedUserObj.groups && <span className="divider" />}
                    <span className="user-groups">{selectedUserObj.groups}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="modal-footer">
        <Button color="secondary" disabled={loading} onClick={handleToggle}>
          <FormattedMessage id="userModal.cancelButton" />
        </Button>
        <Button color="primary" disabled={loading || createButtonDisabled} onClick={handleSubmit}>
          <FormattedMessage id="userModal.createButton" />
        </Button>
      </div>
    </ModalMd>
  );
};

CreateUserGroupModal.propTypes = {
  allowedRoles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onFormatIntl: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  selectedUsers: PropTypes.object,
  userGroups: PropTypes.array,
  users: PropTypes.array.isRequired,
};

export default IntlFormatterHOC(CreateUserGroupModal);
