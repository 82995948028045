import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

const IntlFormatterHOC = ComponentToWrap => {
  const HOCWrapper = ({ intl, ...props }) => {
    const handleFormatIntl = id => {
      return intl.formatMessage({ id });
    };

    return <ComponentToWrap {...props} onFormatIntl={handleFormatIntl} />;
  };

  HOCWrapper.propTypes = {
    intl: PropTypes.any.isRequired,
  };

  return injectIntl(HOCWrapper);
};

export default IntlFormatterHOC;
