import './styles.scss';

import * as Sentry from '@sentry/react';
import {
  Button,
  InputSearchTypeaheadGroups,
  InputTextbox,
  LoadingSpinnerMd,
  ModalMd,
  ModalSm,
  TooltipNoHandler,
  Utils,
} from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../../utils/intlFormatterHOC';
import { getUsersInUserGroup } from '../../../../webAPIs/userCollaboration';

const EditUserGroupModal = ({
  allowedRoles,
  isOpen,
  onDeleteUserGroup,
  onFormatIntl,
  onSubmit,
  onToggleModal,
  userGroup,
  userGroups,
  users,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [initialExistingUsers, setInitialExistingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersForListDisplay, setSelectedUsersForListDisplay] = useState([]);

  const createUserGroupItems = useCallback(() => {
    const userGroupsArr = userGroups.map(obj => ({
      id: obj.id,
      displayName: obj.name,
      subGroup: [],
    }));

    userGroupsArr.push({
      id: 'default',
      displayName: 'Default',
      subGroup: [],
    });

    userGroupsArr.forEach(userGroup => {
      const usersBelongingToUserGroup = users
        .filter(({ groups }) =>
          userGroup.displayName === 'Default' ? groups.length === 0 : groups.indexOf(userGroup.displayName) > -1
        )
        .map(({ id, name, email }) => ({
          id,
          displayName: name,
          displayAdditional: email,
        }));

      userGroup.subGroup = usersBelongingToUserGroup;
    });

    return userGroupsArr;
  }, [userGroups, users]);

  const userGroupItems = useMemo(() => createUserGroupItems(), [createUserGroupItems]);

  const handleDeleteGroup = () => {
    const users = selectedUsers.map(userObj => userObj.id);

    onDeleteUserGroup(userGroup.id, users);
    handleToggle();
  };

  const handleInputChange = event => {
    setGroupName(event.currentTarget.value);
  };

  const handleRemoveUser = event => {
    const userId = event.target.id;
    const filteredSelectedUsers = selectedUsers.filter(selectedUserObj => selectedUserObj.id !== userId);

    handleSelectUser(filteredSelectedUsers);
  };

  const handleSelectUser = useCallback(
    items => {
      const itemsCopy = Utils.deepClone(items);

      itemsCopy.forEach(item => {
        const user = users.find(user => user.id === item.id);
        const { groups, roles } = user || { groups: [], roles: [] };

        item.roles = roles
          .map(role => {
            const roleDetails = Object.values(allowedRoles).filter(({ id }) => id === role)[0];

            return roleDetails !== undefined ? roleDetails.name : role;
          })
          .sort()
          .join(', ');

        item.groups = [...groups].sort().join(', ');
      });

      setSelectedUsers(items);
      setSelectedUsersForListDisplay(
        itemsCopy.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
      );
    },
    [allowedRoles, users]
  );

  const handleSubmit = () => {
    const addedUsers = selectedUsers.filter(
      selectedUserObj => !initialExistingUsers.some(userObj => userObj.id === selectedUserObj.id)
    );
    const removedUsers = initialExistingUsers.filter(
      initialUserObj => !selectedUsers.some(userObj => userObj.id === initialUserObj.id)
    );

    const usersToAdd = addedUsers.map(userObj => ({
      id: userObj.id,
      email: userObj.displayAdditional,
      name: userObj.displayName,
    }));

    const usersToRemove = removedUsers.map(userObj => userObj.id);

    onSubmit(userGroup.id, groupName, usersToAdd, usersToRemove);
    handleToggle();
  };

  const handleToggle = () => {
    onToggleModal();
  };

  const handleToggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  useEffect(() => {
    getUsersInUserGroup(userGroup.id)
      .then(res => {
        const selectedUsersArr = res.data.map(({ user_id, name, email }) => ({
          id: user_id,
          displayName: name,
          displayAdditional: email,
        }));

        setGroupName(userGroup.name);
        setInitialExistingUsers(selectedUsersArr);
        setLoading(false);
        handleSelectUser(selectedUsersArr);
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }, [handleSelectUser, userGroup]);

  return (
    <ModalMd
      classes={classnames('edit-user-group-modal')}
      onHandleClose={handleToggle}
      open={isOpen}
      title={onFormatIntl('userModal.editUserGroupTitle')}
    >
      <div className="modal-body">
        {loading && <LoadingSpinnerMd />}
        {!loading && (
          <>
            <InputTextbox
              classes="group-name-textbox"
              label={onFormatIntl('userModal.userGroupNameLabel')}
              name="groupNameInput"
              onChange={handleInputChange}
              placeholder={onFormatIntl('userModal.userGroupNamePlaceholder')}
              value={groupName}
            />
            <div className="typeahead-wrapper">
              <InputSearchTypeaheadGroups
                classes="select-users-typeahead"
                label={onFormatIntl('userModal.selectUsersLabel')}
                items={userGroupItems}
                onSelect={handleSelectUser}
                placeholder={onFormatIntl('userModal.selectUsersPlaceholder')}
                selectedItems={selectedUsers}
              />
              {selectedUsers.length > 0 && (
                <span className="selected-user-caption">{`${selectedUsers.length} user(s) selected`}</span>
              )}
              <span className="info-icon" />
              <TooltipNoHandler
                infoArr={['You can only select multiple users who have similar roles']}
                showOnClick={false}
              />
            </div>
            <div className="selected-users-list">
              {selectedUsersForListDisplay.map((selectedUserObj, i) => (
                <div className="list-item" key={i}>
                  <div className="top-row">
                    <span className="user-name">{selectedUserObj.displayName}</span>
                    <span className="user-email">{selectedUserObj.displayAdditional}</span>
                    <span className="remove-button" id={selectedUserObj.id} onClick={handleRemoveUser} />
                  </div>
                  <div className="bottom-row">
                    <span className="user-roles">{selectedUserObj.roles}</span>
                    {selectedUserObj.groups && <span className="divider" />}
                    <span className="user-groups">{selectedUserObj.groups}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="modal-footer">
        <div>
          <Button classes="delete-group-button" color="secondary" disabled={loading} onClick={handleToggleConfirmModal}>
            <FormattedMessage id="userModal.deleteGroupButton" />
          </Button>
        </div>
        <div>
          <Button classes="cancel-button" color="secondary" disabled={loading} onClick={handleToggle}>
            <FormattedMessage id="userModal.cancelButton" />
          </Button>
          <Button classes="save-button" color="primary" disabled={loading} onClick={handleSubmit}>
            <FormattedMessage id="userModal.saveButton" />
          </Button>
        </div>
      </div>
      {confirmModalOpen && (
        <ModalSm
          classes="confirm-modal"
          onHandleClose={handleToggleConfirmModal}
          open={confirmModalOpen}
          title={onFormatIntl('userModal.confirmTitle')}
        >
          <div className="modal-subheader">
            <span className="warning-icon" />
            <span className="warning-text">You are about to delete the group</span>
          </div>
          <div className="modal-body">
            <span className="message">
              If you delete group, then the users will not be able to share with each other going forward.
            </span>
          </div>
          <div className="modal-footer">
            <Button color="secondary" onClick={handleToggleConfirmModal}>
              <FormattedMessage id="userModal.cancelButton" />
            </Button>
            <Button color="primary" disabled={loading} onClick={handleDeleteGroup}>
              Delete Group
            </Button>
          </div>
        </ModalSm>
      )}
    </ModalMd>
  );
};

EditUserGroupModal.propTypes = {
  allowedRoles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onDeleteUserGroup: PropTypes.func,
  onFormatIntl: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  userGroup: PropTypes.object,
  userGroups: PropTypes.array,
  users: PropTypes.array.isRequired,
};

export default IntlFormatterHOC(EditUserGroupModal);
